@font-face {
  font-family: "FuturaLTBold";
  src: url("../src/assets/fonts/FuturaLT-Bold.ttf");
}

@font-face {
  font-family: "FuturaLT";
  src: url("../src/assets/fonts/FuturaLT.ttf");
}

@font-face {
  font-family: "UrbanistReg";
  src: url("../src/assets/fonts/Urbanist-Regular.ttf");
}

@font-face {
  font-family: "UrbanistBold";
  src: url("../src/assets/fonts/Urbanist-Bold.ttf");
}

@font-face {
  font-family: "UrbanistSemiBold";
  src: url("../src/assets/fonts/Urbanist-SemiBold.ttf");
}

@font-face {
  font-family: "ManropeBold";
  src: url("./assets/fonts/Manrope-Bold.ttf");
}

@font-face {
  font-family: "ManropeExtraBold";
  src: url("./assets/fonts/Manrope-ExtraBold.ttf");
}

@font-face {
  font-family: "ManropeExtraLight";
  src: url("./assets/fonts/Manrope-ExtraLight.ttf");
}

@font-face {
  font-family: "ManropeLight";
  src: url("./assets/fonts/Manrope-Light.ttf");
}

@font-face {
  font-family: "ManropeMedium";
  src: url("./assets/fonts/Manrope-Medium.ttf");
}

@font-face {
  font-family: "ManropeRegular";
  src: url("./assets/fonts/Manrope-Regular.ttf");
}

@font-face {
  font-family: "ManropeSemiBold";
  src: url("./assets/fonts/Manrope-SemiBold.ttf");
}

@font-face {
  font-family: "MerriweatherBold";
  src: url("./assets/fonts/Merriweather-Bold.ttf");
}

@font-face {
  font-family: "MerriweatherLight";
  src: url("./assets/fonts/Merriweather-Light.ttf");
}

@font-face {
  font-family: "MerriweatherRegular";
  src: url("./assets/fonts/Merriweather-Regular.ttf");
}

@font-face {
  font-family: "Cinzel";
  src: url("./assets/fonts/Cinzel-Medium.ttf");
}

@font-face {
  font-family: "CinzelBold";
  src: url("./assets/fonts/Cinzel-Bold.ttf");
}

@font-face {
  font-family: "RalewayMedium";
  src: url("./assets/fonts/Raleway-Medium.ttf");
}

@font-face {
  font-family: "RalewaySemiBold";
  src: url("./assets/fonts/Raleway-SemiBold.ttf");
}

@font-face {
  font-family: "Questrial";
  src: url("./assets/fonts/Questrial-Regular.ttf");
}

@font-face {
  font-family: "BebasNeue";
  src: url("./assets/fonts/BebasNeue-Regular.ttf");
}

@font-face {
  font-family: "RalewayRegular";
  src: url("./assets/fonts/Raleway-Regular.ttf");
}

@font-face {
  font-family: "InterLight";
  src: url("./assets/fonts/Inter-Light.ttf");
}

:root {
  --navbar-link-color: white;
  --navbar-active-color: #0a76db;
  --text-light-gray: #8c8c8c;
  --text-light-black: #2b2b2b;
  --home-text-color: #d9d9d9;
  --home-btn-color: #303030;
  --footer-background-color: #000e26;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "UrbanistReg";
}

p {
  font-family: "ManropeRegular";
}

body {
  background-color: #f7fcff;
  overflow-x: hidden;
}

.nav-link {
  cursor: pointer;
}

/* ______________BUTTONS______________________ */
.eye_btn {
  padding: 15px 45px;
  text-align: center;
  background-color: #fe0000;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  border: transparent;
  display: block;
}

.eye_btn:hover {
  background-position: right center;
  color: #fff;
  background-color: #ea5050;
  text-decoration: none;
  border: transparent;
}

.eye_btn33 {
  background-color: #fe0000;
  border: 1px solid #fe0000;
  color: white;
  font-size: 17px;
  font-family: ManropeBold;
}

.eye_btn33:hover {
  background-color: #2b2b2b;
  border: 1px solid #2b2b2b;
  color: white;
}

.drop_country {
  background-color: #fe0000;
  margin: 10px;
  padding: 12px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 5px;
  border: transparent;
  display: block;
}

.drop_country:hover {
  background-position: right center;
  color: #fff;
  background-color: #ea5050;
  text-decoration: none;
}

/* __________________________________________ */