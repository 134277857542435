.logo_img {
    width: 22%;
}

.first_section_p {
    font-size: 40px !important;
    /* font-weight: bold; */
    font-family: Arial, Helvetica, sans-serif;
}

.first_section_p_1 {
    font-size: 40px !important;
    font-weight: 500;
    font-family: Cinzel;
    background-color: black;
}

.admission_section_p_1 {
    font-size: 40px !important;
    font-weight: 500;
    font-family: Cinzel;
}

.first_section_p_2 {
    font-size: 25px !important;
    font-family: Arial, Helvetica, sans-serif;
}

.first_section_h5 {
    font-size: 25px !important;
    font-family: Arial, Helvetica, sans-serif;
}

.section_section_h2 {
    font-size: 50px !important;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
}

.hover-effect:hover {
    transform: scale(1.05);
    cursor: pointer;
    transition: transform 0.3s ease;
}

.section_third_card_title {
    font-family: Arial, Helvetica, sans-serif;
    text-transform: capitalize;
}

.ribbon {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 93px;
    height: 93px;
    text-align: right;
}

.ribbon span {
    font-size: 0.8rem;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    line-height: 32px;
    transform: rotate(45deg);
    width: 125px;
    display: block;
    background: #79a70a;
    background: linear-gradient(#9bc90d 0%, #79a70a 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 17px;
    right: -29px;
}

.ribbon span::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #79A70A;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79A70A;
}

.ribbon span::after {
    content: '';
    position: absolute;
    right: 0%;
    top: 100%;
    z-index: -1;
    border-right: 3px solid #79A70A;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79A70A;
}

/* Red ribbon */
.red span {
    background: linear-gradient(#f70505 0%, #8f0808 100%);
}

.red span::before {
    border-left-color: #8f0808;
    border-top-color: #8f0808;
}

.red span::after {
    border-right-color: #8f0808;
    border-top-color: #8f0808;
}

/* Blue ribbon */
.blue span {
    background: linear-gradient(#2989d8 0%, #1e5799 100%);
}

.blue span::before {
    border-left-color: #1e5799;
    border-top-color: #1e5799;
}

.blue span::after {
    border-right-color: #1e5799;
    border-top-color: #1e5799;
}

/* Green ribbon */
.green span {
    background: linear-gradient(#79a70a 0%, #9bc90d 100%);
}

.green span::before {
    border-left-color: #9bc90d;
    border-top-color: #9bc90d;
}

.green span::after {
    border-right-color: #9bc90d;
    border-top-color: #9bc90d;
}


/* Orange ribbon */
.orange span {
    background: linear-gradient(rgb(246, 161, 5) 0%, orange 100%);
}

.orange span::before {
    border-left-color: orange;
    border-top-color: orange;
}

.orange span::after {
    border-right-color: orange;
    border-top-color: orange;
}


/* Pink ribbon */
.pink span {
    background: linear-gradient(rgb(250, 3, 44) 0%, rgb(239, 80, 106) 100%);
}

.pink span::before {
    border-left-color: rgb(239, 80, 106);
    border-top-color: rgb(239, 80, 106);
}

.pink span::after {
    border-right-color: rgb(239, 80, 106);
    border-top-color: rgb(239, 80, 106);
}

.section_fourth_li {
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 15px;
    font-size: 18px;
}

.button-33 {
    background-color: #fbd7d7;
    /* light red shade */
    border-radius: 100px;
    box-shadow: rgba(187, 44, 44, .2) 0 -25px 18px -14px inset, rgba(187, 44, 44, .15) 0 1px 2px, rgba(187, 44, 44, .15) 0 2px 4px, rgba(187, 44, 44, .15) 0 4px 8px, rgba(187, 44, 44, .15) 0 8px 16px, rgba(187, 44, 44, .15) 0 16px 32px;
    color: red;
    /* text color red */
    cursor: pointer;
    display: inline-block;
    font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    border: 0;
    font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-33:hover {
    box-shadow: rgba(187, 44, 44, .35) 0 -25px 18px -14px inset, rgba(187, 44, 44, .25) 0 1px 2px, rgba(187, 44, 44, .25) 0 2px 4px, rgba(187, 44, 44, .25) 0 4px 8px, rgba(187, 44, 44, .25) 0 8px 16px, rgba(187, 44, 44, .25) 0 16px 32px;
    transform: scale(1.05) rotate(-1deg);
}

.gradient-custom {
    background: radial-gradient(50% 123.47% at 50% 50%, #00ff94 0%, #720059 100%),
        linear-gradient(121.28deg, #669600 0%, #ff0000 100%),
        linear-gradient(360deg, #0029ff 0%, #8fff00 100%),
        radial-gradient(100% 164.72% at 100% 100%, #6100ff 0%, #00ff57 100%),
        radial-gradient(100% 148.07% at 0% 0%, #fff500 0%, #51d500 100%);
    background-blend-mode: screen, color-dodge, overlay, difference, normal;
}

.carousel-indicators {
    border-radius: 50%;
    width: 10px;
    height: 10px;
}

.carousel-control-prev-icon::after {
    display: none;
}

.carousel-control-next-icon::after {
    display: none;
}

@media (max-width: 576px) {
    .logo_img {
        width: 50%;
    }

    .first_section_p_1 {
        font-size: 23px !important;
    }

    .admission_section_p_1 {
        font-size: 23px !important;
    }

    .first_section_p {
        font-size: 30px !important;
    }

    .first_section_p_2 {
        font-size: 18px !important;
    }

    .section_section_h2 {
        font-size: 25px !important;
    }
}